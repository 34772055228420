import {
  createBrowserRouter,
  createRoutesFromElements,
  Route
} from 'react-router-dom';

import {
  CHAT_BOT,
  CHAT_BOT_IMPORT_CONTEXT,
  CHAT_BOT_AI_SETTINGS
} from './routes';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path={CHAT_BOT_IMPORT_CONTEXT.ROUTE_RELATIVE}
        lazy={() => import('./pages/ImportContext')}
      />
      <Route
        path={CHAT_BOT_AI_SETTINGS.ROUTE_RELATIVE}
        lazy={() => import('./pages/AISettings')}
      />
      <Route
        path={`${CHAT_BOT.ROUTE_RELATIVE}/live-chats`}
        lazy={() => import('./pages/LiveChats')}
      >
        <Route index lazy={() => import('./pages/LiveChatsIndex')} />
        <Route path=":id" lazy={() => import('./pages/LiveChat')} />
      </Route>
      <Route
        path={`${CHAT_BOT.ROUTE_RELATIVE}/live-chat-settings`}
        lazy={() => import('./pages/LiveChatSettings')}
      >
        <Route
          path="teams"
          lazy={() => import('./pages/LiveChatSettingTeams')}
        />
        <Route
          path="templates"
          lazy={() => import('./pages/LiveChatSettingTemplates')}
        />
      </Route>
    </>
  )
);
